<template>
  <v-container fluid class="px-0 mb-5">
    <v-row>
      <v-col cols="12">
        <v-btn
          v-if="$vuetify.breakpoint.xsOnly"
          text
          x-small
          style="vertical-align: middle"
          @click="$emit('backtoUserSetting')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="text-h5" style="vertical-align: middle">
          {{ $t("userInformation") }}
        </span>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <span class="text-h6">
          {{ $t("personalDetails") }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-form class="mt-5">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="nameValue"
            :error-messages="nameErrors"
            :label="$t('fullName')"
            outlined
            required
            @input="enableBtn('nameValue')"
            @blur="enableBtn('nameValue')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="phoneValue"
            :error-messages="phoneErrors"
            :label="$t('phoneNumber')"
            outlined
            required
            @input="enableBtn('phoneValue')"
            @blur="enableBtn('phoneValue')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            disabled
            v-model="emailValue"
            :label="$t('emailAddress')"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="bottomNavbar"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
        >
          <v-row class="px-4 py-2">
            <v-btn
              color="primary"
              block
              large
              class="text-h6 rounded-lg"
              :disabled="$v.$invalid || disableUpdate"
              @click="submit"
            >
              Update
            </v-btn>
          </v-row>
        </v-bottom-navigation>
        <v-col v-else cols="12">
          <v-btn
            large
            color="primary"
            :disabled="$v.$invalid || disableUpdate"
            @click="submit"
          >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);

export default {
  name: "UserInformation",
  mixins: [validationMixin],
  validations: {
    nameValue: { required, maxLength: maxLength(80), minLength: minLength(1) },
    phoneValue: { required, phone },
  },
  data: () => ({
    inputFocused: false,
    bottomNavbar: true,
    nameValue: "",
    emailValue: "",
    phoneValue: "",
    disableUpdate: true,
  }),
  computed: {
    userdata() {
      return this.$store.state.User.user;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.nameValue.$dirty) return errors;
      !this.$v.nameValue.required && errors.push(this.$t("callerRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phoneValue.$dirty) return errors;
      !this.$v.phoneValue.required &&
        errors.push(this.$t("phoneRequiredError"));
      !this.$v.phoneValue.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      return errors;
    },
  },
  created() {
    setTimeout(() => {
      this.phoneValue = this.userdata.phone;
      this.nameValue = this.userdata.name;
      this.emailValue = this.userdata.email;
    }, 1000);
  },
  methods: {
    enableBtn(fieldName) {
      this.$v[fieldName].$touch();
      this.disableUpdate = false;
    },

    submit() {
      let data = {
        userId: this.userdata.id,
        name: this.nameValue,
        phone: this.phoneValue,
        email: this.userdata.email,
      };
      this.$emit("update-user", data);
      this.disableUpdate = true;
    },
  },
};
</script>
<style scoped>
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #f5f5f5 !important;
}
</style>
