var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 mb-5", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { "vertical-align": "middle" },
                      attrs: { text: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backtoUserSetting")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "text-h5",
                  staticStyle: { "vertical-align": "middle" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("userInformation")) + " ")]
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c("v-col", { attrs: { cols: "12" } }, [_c("v-divider")], 1)
            : _vm._e(),
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("span", { staticClass: "text-h6" }, [
              _vm._v(" " + _vm._s(_vm.$t("personalDetails")) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c("v-row", [_c("v-divider")], 1),
      _c(
        "v-form",
        { staticClass: "mt-5" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.nameErrors,
                      label: _vm.$t("fullName"),
                      outlined: "",
                      required: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.enableBtn("nameValue")
                      },
                      blur: function ($event) {
                        return _vm.enableBtn("nameValue")
                      },
                    },
                    model: {
                      value: _vm.nameValue,
                      callback: function ($$v) {
                        _vm.nameValue = $$v
                      },
                      expression: "nameValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.phoneErrors,
                      label: _vm.$t("phoneNumber"),
                      outlined: "",
                      required: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.enableBtn("phoneValue")
                      },
                      blur: function ($event) {
                        return _vm.enableBtn("phoneValue")
                      },
                    },
                    model: {
                      value: _vm.phoneValue,
                      callback: function ($$v) {
                        _vm.phoneValue = $$v
                      },
                      expression: "phoneValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: "",
                      label: _vm.$t("emailAddress"),
                      outlined: "",
                    },
                    model: {
                      value: _vm.emailValue,
                      callback: function ($$v) {
                        _vm.emailValue = $$v
                      },
                      expression: "emailValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                      model: {
                        value: _vm.bottomNavbar,
                        callback: function ($$v) {
                          _vm.bottomNavbar = $$v
                        },
                        expression: "bottomNavbar",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-4 py-2" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-h6 rounded-lg",
                              attrs: {
                                color: "primary",
                                block: "",
                                large: "",
                                disabled: _vm.$v.$invalid || _vm.disableUpdate,
                              },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" Update ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            color: "primary",
                            disabled: _vm.$v.$invalid || _vm.disableUpdate,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Update ")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }